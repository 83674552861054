import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { above } from '../../../styles/tools/media';
import aspectRatio from '../../../styles/tools/aspectRatio';

interface MapContainerType {
	mapHeight?: string
}

const MapContainer = styled.div<MapContainerType>`
	height: ${(props: MapContainerType)  => (props.mapHeight ? props.mapHeight : '0')};
	padding-top: ${aspectRatio('1:1')};
	background-color: var(--primaryAccent);

	${above(breakpoints[600], () => css`
		padding-top: ${aspectRatio('16:9')};

	`)}
`;

export default {
	MapContainer
};
