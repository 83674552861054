import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Styled from './index.styled.ts';
import { clientConfig } from '../../../env/config';

const GoogleMap = ({ mapData, markerData, mapHeight }) => {
	const [ref, inView, entry] = useInView({
		triggerOnce: true,
		rootMargin: '200px 0px',
	});

	const mapRef = useRef(null);
	const [mapMarkers, setMapMarkers] = useState([]);

	const setIconState = (marker, active) => {
		if (active) {
			marker.setIcon(marker.iconActive);
			marker.setZIndex(2);
		} else {
			marker.setIcon(marker.iconDefault);
			marker.setZIndex(1);
		}
	};

	const setMapMarkerActiveStates = (mapMarker, markersArr) => {
		markersArr.forEach(marker => {
			marker === mapMarker ? setIconState(marker, true) : setIconState(marker, false);
		});
	};

	const addInfoWindow = (mapMarker, newMarker, infoWindow, markersArr) => {
		const map = mapRef.current;
		mapMarker.addListener('click', () => {
			setMapMarkerActiveStates(mapMarker, markersArr);
			infoWindow.setContent(newMarker.infoWindowPopup);
			infoWindow.open(map, mapMarker);
		});

		infoWindow.addListener('closeclick', () => {
			mapMarker.setIcon(newMarker.icon);
		});

		map.addListener('click', () => {
			mapMarker.setIcon(newMarker.icon);
			infoWindow.close();
		});
	};

	const setCustomMarkers = () => {
		const map = mapRef.current;
		const markersArr = [];
		const infoWindow = new window.google.maps.InfoWindow();

		mapMarkers.forEach(marker => {
			marker.setMap(null);
		});

		markerData.forEach(marker => {
			const newMarker = marker[0] ? marker[0] : marker;

			const mapMarker = new window.google.maps.Marker({
				...newMarker,
				map,
			});

			markersArr.push(mapMarker);
			setMapMarkers(markersArr);
			if (newMarker.infoWindowPopup)
				addInfoWindow(mapMarker, newMarker, infoWindow, markersArr);
		});
	};

	const updateMap = () => {
		setCustomMarkers();
	};

	const setMarkers = () => {
		// eslint-disable-next-line no-new
		new window.google.maps.Marker({
			...markerData,
			map: mapRef.current,
		});
	};

	const createMap = () => {
		const map = new window.google.maps.Map(entry.target, {
			...mapData,
			disableDefaultUI: window.innerWidth <= 760,
		});

		if (mapData.zoom) map.setZoom(mapData.zoom);
		mapRef.current = map;

		if (!Array.isArray(markerData)) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const mapMarker = setMarkers();
			return;
		}

		setCustomMarkers();
	};

	const [mapNotIncluded, setMapNotIncluded] = useState(true);

	useEffect(() => {
		if (inView && mapNotIncluded) {
			const script = document.createElement('script');

			script.src = `https://maps.googleapis.com/maps/api/js?key=${
				clientConfig().googleMapApiKey
			}`;

			document.head.appendChild(script);
			script.addEventListener('load', createMap);
			setMapNotIncluded(false);
		}

		if (inView && !mapNotIncluded) {
			updateMap();
		}
	}, [inView, markerData]);

	return <Styled.MapContainer id="google-map" ref={ref} mapHeight={mapHeight} />;
};

export default GoogleMap;
