import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Styled from './index.styled.js';
import Button from '../../atoms/Button';
import GoogleMap from '../../atoms/GoogleMap';
import Browser from '../../../lib/browser';

const Location = ({
	title,
	subtitle,
	description,
	richCopy,
	buttonText,
	buttonLink,
	buttonCategory,
	buttonAction,
	buttonLabel,
	googleMapsData,
	anchorId,
}) => (
	<Styled.Section id={anchorId || ''}>
		<Styled.Inner>
			<Styled.Title as="h2">{title}</Styled.Title>

			<Styled.Card>
				<Styled.Subtitle as="h3">{subtitle}</Styled.Subtitle>
				<Styled.Body>{description}</Styled.Body>
				<Styled.Body as="div">
					{documentToReactComponents(richCopy, {
						renderText: text =>
							text.split('\n').flatMap((textCopy, i) => [i > 0 && <br key={i} />, textCopy]),
					})}
				</Styled.Body>
				<Button
					buttonText={buttonText}
					buttonLink={buttonLink}
					buttonCategory={buttonCategory}
					buttonAction={buttonAction}
					buttonLabel={buttonLabel}
					target="_blank"
					rel="noopener noreferrer"
					buttonType="tertiary"
					callback={event => Browser.handleClick(event, buttonLink)}
				/>
			</Styled.Card>

			<Styled.GoogleMaps__Wrapper>
				<GoogleMap {...googleMapsData} />
			</Styled.GoogleMaps__Wrapper>
		</Styled.Inner>
	</Styled.Section>
);

export default Location;
