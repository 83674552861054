import styled, { css } from 'styled-components';
import { Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing, margins } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import { smBodyLight, bigTitleRegular, xlTitleRegular } from '../../atoms/Typography/index.styled';

const Section = styled.section`
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};
	background-color: var(--primaryBackground);
	overflow: hidden;

`;

const Inner = styled(Grid)`
	grid-auto-rows: auto;
`;

const Title = styled(xlTitleRegular)`
	grid-column: 1 / -1;
	margin-bottom: ${gap[16]};
	color: var(--primaryForeground);

	${below(breakpoints[960], () => css`
		order: 2;
	`)}

	${above(breakpoints[960], () => css`
    grid-row: 1;
		margin-bottom: ${gap[48]};
		text-align: center;
	`)}
`;

const Subtitle = styled(bigTitleRegular)`
	margin-bottom: ${gap[16]};
	color: var(--primaryForeground);

	${above(breakpoints[960], () => css`
		margin-bottom: ${gap[32]};
	`)}
`;

const Body = styled(smBodyLight)`
	margin-bottom: ${gap[32]};

	${above(breakpoints[960], () => css`
		margin-bottom: ${gap[40]};
	`)}
`;

const MobileClickable = styled.div`
	${above(breakpoints[600], () => css`
		width: 100%;
		height: 100%;
		pointer-events: none;
	`)}
`;

const Card = styled.div`
	grid-column: 1 / -1;

	${below(breakpoints[960], () => css`
		order: 3;
		padding-right: ${gap[24]};
	`)}

	${above(breakpoints[960], () => css`
		position: relative;
		max-width: 560px;
		grid-column: 7 / -1;
    grid-row: 2;
    align-self: start;
    justify-self: end;
		margin-top: ${gap[56]};
		padding: ${gap[48]};
		background-color: var(--white);
		box-shadow: ${gap[24]} ${gap[24]} 0 0 var(--primaryAccent);
		z-index: 5;
	`)}

	${above(breakpoints[1440], () => css`
		box-shadow: ${gap[32]} ${gap[32]} 0 0 var(--primaryAccent);
	`)}
`;

const GoogleMaps__Wrapper = styled.div`
	grid-column: 1 / 10;

	${below(breakpoints[960], () => css`
		position: relative;
		left: -${margins[16]};
		width: 100vw;
		margin-bottom: ${spacing[48]};
		order: 1;
	`)}

	${above(breakpoints[600], () => css`
		left: -${margins[24]};
	`)}

	${above(breakpoints[960], () => css`
    grid-row: 2;
		left: 0;
		width: 100%;
		margin-bottom: ${gap[0]};
	`)}
`;

export default {
	Section,
	Inner,
	Title,
	Subtitle,
	Body,
	Card,
	GoogleMaps__Wrapper,
	MobileClickable
};
